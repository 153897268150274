<template>
  <div class="container-sm mt-5">
    <h1>To-do list</h1>
    <div class="row mb-3 mt-4">
      <div class="col-9">
        <div class="input-group">
          <input type="text" id="todoValue" v-model="title" class="form-control" placeholder="To-do" aria-label="To-do"
                 aria-describedby="button-addon2">
          <button class="btn btn-primary" @click="addTodo" type="button" id="button-addon2">Add a todo</button>
        </div>
      </div>
      <div class="col-3">
        <select class="form-select" v-model="selectedFilter" @change="filterList" aria-label="Filter">
          <option selected value="all">Show all</option>
          <option value="1">Completed</option>
          <option value="0">Need to do</option>
        </select>
      </div>
    </div>
    <span class="badge text-bg-danger mb-3" v-if="message">{{ message }}</span>
    <div class="list-group js-list-group">
      <TodoCard :key="todo.id" :id="todo.id" :state="todo.completed" v-for="todo in this.todos" :title="todo.title"
                @refreshList="refreshList" @deleteTodo="onDeleteTodo"/>
    </div>
  </div>
</template>

<script>
import TodoCard from "@/components/TodoCard.vue";
import {Sortable, Plugins} from '@shopify/draggable';

export default {
  name: 'HomeView',
  components: {
    TodoCard
  },
  data() {
    return {
      title: '',
      message: '',
      selectedFilter: 'all',
      todos: []
    }
  },
  mounted() {
    this.todos = JSON.parse(localStorage.getItem('todos')) ?? [];

    const listContainer = document.querySelector('.js-list-group');

    const listSort = new Sortable(listContainer, {
      draggable: '.js-list-item',
      handle: '.handle',
      mirror: {
        constrainDimensions: true,
      },
      plugins: [Plugins.SortAnimation],
      swapAnimation: {
        duration: 200,
        easingFunction: 'ease-in-out',
      },
    });

    listSort.on('sortable:stop', (evt) => {
      this.moveInArray(evt.data.oldIndex, evt.data.newIndex);
    });

  },
  methods: {
    addTodo() {
      let todosStorage = JSON.parse(localStorage.getItem('todos')) ?? [];

      if (!this.title) {
        this.message = 'Enter a name'
        return;
      }

      let todoObj = {
        id: this.idHandler(todosStorage),
        title: this.title,
        completed: 0,
      };

      todosStorage.push(todoObj);

      localStorage.setItem('todos', JSON.stringify(todosStorage));

      this.todos = todosStorage;
    },
    idHandler(storage) {
      if (storage.length > 0) {
        let lastItem = storage.slice(-1)[0];

        return lastItem.id + 1;
      } else {
        return 1;
      }
    },
    onDeleteTodo(id) {
      const currentTodoIndex = this.todos.findIndex((todo) => todo.id === id);

      if (currentTodoIndex !== -1) {
        this.todos.splice(currentTodoIndex, 1);
        localStorage.setItem('todos', JSON.stringify(this.todos));
      }
    },
    moveInArray(fromIndex, toIndex) {
      let element = this.todos[fromIndex];
      this.todos.splice(fromIndex, 1);
      this.todos.splice(toIndex, 0, element);

      localStorage.setItem('todos', JSON.stringify(this.todos));
    },
    refreshList() {
      const todosStorage = JSON.parse(localStorage.getItem('todos')) ?? [];

      this.todos = todosStorage;
    },
    filterList() {
      const todosStorage = JSON.parse(localStorage.getItem('todos'));

      if (todosStorage.length <= 0) return;

      if (this.selectedFilter === 'all') {
        this.todos = todosStorage;
      } else {
        const filteredStorage = todosStorage.filter(todo => todo.completed == this.selectedFilter);

        this.todos = filteredStorage;
      }
    }
  }
}
</script>
