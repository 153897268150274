<template>
  <li class="list-group-item list-item js-list-item d-flex justify-content-between align-items-center" :id="id"
      :class="{'active': todoState === 1}">
    <span class="text-black d-flex gap-2 align-items-center">
    <IconGrip class="handle"/>
       <p class="mb-0" v-if="!availableEdit">{{ title }}</p>
        <div class="input-group input-group-sm" v-if="availableEdit">
  <input type="text" class="form-control" v-model="changeTitle" :placeholder="title" :aria-label="title"
         aria-describedby="button-addon2">
  <button class="btn btn-primary btn-sm" type="button" id="button-addon2" @click="editTodo(id)">Change</button>
</div>
    </span>
    <div class="btn-group" role="group" aria-label="Basic example">
      <button type="button" class="btn btn-success btn-sm" @click="changeState(id)">Complete</button>
      <button type="button" class="btn btn-warning btn-sm" @click="availableEdit = !availableEdit">Edit</button>
      <button type="button" class="btn btn-delete btn-danger btn-sm" @click="!availableEdit ? showPromt = true : null">
        Delete
        <div v-if="showPromt" @mouseenter="showPromt = true" @mouseleave="showPromt = false"
             class="toast show custom-toast bg-light" role="alert" aria-live="assertive"
             aria-atomic="true">
          <div class="toast-body">
            <p class="text-black text-start mb-0">Are you sure?</p>
            <div class="mt-2 pt-2 border-top d-flex gap-1">
              <button type="button" class="btn btn-danger btn-sm" @click="deleteTodo(id)">Delete</button>
              <button type="button" class="btn btn-secondary btn-sm" @click.stop="showPromt = false">Close</button>
            </div>
          </div>
        </div>
      </button>
    </div>
  </li>
</template>

<script>

import IconGrip from "@/components/icons/IconGrip.vue";

export default {
  name: 'TodoCard',
  components: {
    IconGrip
  },
  props: {
    id: Number,
    title: String,
    state: Number
  },
  data() {
    return {
      showPromt: false,
      availableEdit: false,
      todoState: this.state,
      changeTitle: ''
    }
  },
  methods: {
    changeState(id) {
      let todosStorage = JSON.parse(localStorage.getItem('todos')) ?? [];

      if (todosStorage.length <= 0) return;

      let currentTodo = todosStorage.find((todo) => id === todo.id);

      if (!currentTodo) return;

      currentTodo.completed = currentTodo.completed === 1 ? 0 : 1;
      this.todoState = this.todoState === 1 ? 0 : 1;

      localStorage.setItem('todos', JSON.stringify(todosStorage));
    },
    deleteTodo(id) {
      // Emit a deleteTodo event to notify the parent component
      this.$emit('deleteTodo', id);
    },
    editTodo(id) {
      let todosStorage = JSON.parse(localStorage.getItem('todos')) ?? [];

      if (todosStorage.length <= 0) return;

      let currentTodo = todosStorage.find((todo) => id === todo.id);

      if (!currentTodo) return;

      if (this.changeTitle === '') return;

      currentTodo.title = this.changeTitle;

      localStorage.setItem('todos', JSON.stringify(todosStorage));

      this.$emit('refreshList', id);

      this.availableEdit = false;
    },
    getCurrentTodo(id) {
      let todosStorage = JSON.parse(localStorage.getItem('todos')) ?? [];

      if (todosStorage.length <= 0) return;

      let currentTodo = todosStorage.find((todo) => id === todo.id);

      if (!currentTodo) return;

      return currentTodo;
    }
  }
}
</script>

<style lang="scss">
.list-item {
  &.draggable--original {
    opacity: 0.2;
  }

  .handle {
    cursor: grab;
  }
}

.btn-delete {
  position: relative;

  .custom-toast {
    position: absolute;
    top: calc(100% + 4px);
    right: 0;
    min-width: 140px;
    z-index: 10;
  }
}
</style>